<template>
    <div>
        <van-notice-bar mode="closeable">隧道模拟图标识中仅标注部分大体位置，具体位置以安装位置为准</van-notice-bar>
        <div class="glo-relative">
            <van-image :src=tunnelImg width="100%" :height="height" />
            <van-image class="position" v-for="(item,index) in imgData" :key="index" :style={top:item.top,left:item.left} :src="item.src" :width="item.width" :height="item.height" />
        </div>
        <van-divider>功能模块</van-divider>
        <div class="container home">
            <van-row gutter="20">
                <van-col span="8" v-for="(item,index) in moduleList" :key="index">
                    <div class="module-block" :style="{backgroundColor:item.bgColor}" @click="moduleClick(item)">
                        <div><van-icon class="icon" :name="item.icon" /></div>
                        <div class="name">{{item.name}}</div>
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="back-block" @click="goBack">
            <van-icon name="revoke" />
        </div>
    </div>
</template>

<script>
import tunnelImg1 from '@/assets/tunnel/img/tunnel-fsl.png'
import tunnelImg2 from '@/assets/tunnel/img/tunnel-2.png'
import tunnelImg3 from '@/assets/tunnel/img/tunnel-3.png'
import arrowsImg from '@/assets/tunnel/img/logo/arrows.png' //箭头
import greenImg from '@/assets/tunnel/img/logo/green.png' //绿灯
import camera1Img from '@/assets/tunnel/img/logo/camera.png' //摄像头1
import camera2Img from '@/assets/tunnel/img/logo/camera2.png' //摄像头2
import lightonImg from '@/assets/tunnel/img/logo/lighton.png' //照明
import ventilateImg from '@/assets/tunnel/img/logo/ventilate.png' //通风
import fireImg from '@/assets/tunnel/img/logo/fire.png' //消防1
import alarmImg from '@/assets/tunnel/img/logo/alarm.png' //消防2
import trumpetImg from '@/assets/tunnel/img/logo/trumpet.png' //广播1
import phoneImg from '@/assets/tunnel/img/logo/phone.png' //广播2
export default {
    data(){
        return{
            moduleList:[
                {
                    name:'电力监控',
                    icon:'apps-o',
                    type:'electric',
                    bgColor:'#87c8cf'
                },
                {
                    name:'交通控制',
                    icon:'aim',
                    type:'traffic',
                    bgColor:'#DFA276'
                },
                {
                    name:'实时视频',
                    icon:'video-o',
                    type:'video',
                    bgColor:'#69c59b'
                },
                {
                    name:'照明控制',
                    icon:'eye-o',
                    type:'illumination',
                    bgColor:'#ff9494'

                },
                {
                    name:'通风模块',
                    icon:'desktop-o',
                    type:'ventilate',
                    bgColor:'#5fbadd'
                },
                {
                    name:'消防联动',
                    icon:'volume-o',
                    type:'fireprotection',
                    bgColor:'#86a7c9'

                },
                {
                    name:'电话广播',
                    icon:'phone-o',
                    type:'broadcast',
                    bgColor:'#5fc3b8'
                },
                {
                    name:'预案管理',
                    icon:'bar-chart-o',
                    type:'record',
                    bgColor:'#d24b3d87'

                },
                {
                    name:'报警管理',
                    icon:'warn-o',
                    type:'alarm',
                    bgColor:'#e5bc68d9'
                }
            ],
            imgData:[
                // 交通标示
                {name:'arrows',src:greenImg,width:'35px',height:'12px',top:'14%',left:'5%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'14%',left:'20%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'14%',left:'40%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'14%',left:'60%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'14%',left:'80%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'14%',left:'95%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'5%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'20%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'40%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'60%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'80%'},
                {name:'arrows',src:arrowsImg,width:'12px',height:'12px',top:'72%',left:'95%'},
                // 摄像头
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'4%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'25%'},
                {name:'caram',src:camera2Img,width:'12px',height:'12px',top:'5%',left:'28%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'20%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'56%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'36%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'78%'},
                {name:'caram',src:camera2Img,width:'12px',height:'12px',top:'5%',left:'64%'},
                {name:'caram',src:camera2Img,width:'12px',height:'12px',top:'5%',left:'93%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'86%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'5%',left:'83%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'1%'},
                {name:'caram',src:camera2Img,width:'12px',height:'12px',top:'64%',left:'8%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'20%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'35%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'44%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'78%'},
                {name:'caram',src:camera2Img,width:'12px',height:'12px',top:'64%',left:'68%'},
                {name:'caram',src:camera1Img,width:'12px',height:'12px',top:'64%',left:'88%'},
                // 照明
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'4%',left:'5%'},
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'4%',left:'2%'},
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'4%',left:'92%'},
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'84%',left:'78%'},
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'84%',left:'80%'},
                {name:'lighton',src:lightonImg,width:'12px',height:'12px',top:'84%',left:'84%'},
                // 通风
                {name:'ventilate',src:ventilateImg,width:'36px',height:'12px',top:'18%',left:'22%'},
                {name:'ventilate',src:ventilateImg,width:'36px',height:'12px',top:'18%',left:'82%'},
                {name:'ventilate',src:ventilateImg,width:'36px',height:'12px',top:'80%',left:'4%'},
                {name:'ventilate',src:ventilateImg,width:'36px',height:'12px',top:'80%',left:'18%'},
                {name:'ventilate',src:ventilateImg,width:'36px',height:'12px',top:'80%',left:'84%'},
                // 消防
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'10%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'10%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'12%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'12%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'14%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'14%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'15%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'15%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'75%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'75%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'73%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'73%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'70%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'70%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'4%',left:'71%'},
                {name:'fireAlarm',src:alarmImg,width:'12px',height:'12px',top:'10%',left:'71%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'15%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'18%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'20%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'23%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'67%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'70%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'72%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'75%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'79%'},
                {name:'fireAlarm',src:fireImg,width:'12px',height:'12px',top:'90%',left:'82%'},
                // 电话广播
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'4%',left:'46%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'4%',left:'50%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'4%',left:'55%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'4%',left:'58%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'55%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'58%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'5%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'8%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'10%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'13%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'30%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'33%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'37%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'40%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'46%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'50%'},
                {name:'trumpet',src:trumpetImg,width:'12px',height:'12px',top:'90%',left:'90%'},
                {name:'trumpet',src:phoneImg,width:'12px',height:'12px',top:'90%',left:'94%'},
            ],
            tunnelImg:null,
            height:'180px',
            proId:null
        }
    },
    mounted(){
        this.proId = this.$route.query.id;
        if(this.proId==='test0001'){
            this.tunnelImg = tunnelImg1;
            this.height = '180px'
        }else if(this.proId === 'test0002' || this.proId === 'test0003' || this.proId === 'test0007'){
            this.tunnelImg = tunnelImg2;
            this.height = '180px'
        }else if(this.proId === 'test0004' || this.proId === 'test0005' || this.proId === 'test0006' || this.proId === 'test0008'){
            this.tunnelImg = tunnelImg3;
            this.height = '130px'
        }
    },
    methods:{
        moduleClick(item){
            this.$router.push({path:`/tunnel/${item.type}?id=${this.proId}`});
        },
        goBack(){
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
    padding: 10px;
    .adm-space-item{
        font-size: 2.2rem;
    }
    .module-block{
        text-align: center;
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 20px;
    }
}
.glo-relative{
    position: relative;
    .position{
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
    }
}
.back-block{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #ababab;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #fff;
    &:hover{
        opacity: 0.5;
    }
}
.icon{
    font-size: 2rem;
}
.name{
    font-size: .85rem;
    padding-top: 10px;
}
</style>